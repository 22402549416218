.Logo-image {
  max-width: 100px;
  max-height: 100px;
  width: 100px;
  height: 100%;
  object-fit: contain;
}

.errorBody{
  margin-top: 10vh;
  font-weight: 400;
  color: #444444;
}

unsubscribeBody{
  margin-top: 10vh;
  font-weight: 400;
  color: #444444;
}

.pageHead {
  font-weight: 400;
  font-size: min(6vh, 45px);
  color: #444444;
}
.reasonHead {
  font-size: min(3vh, 22px);
  font-weight: 400;
  color: #444444;
  padding: 1rem;
}

.feedbackHead {
  font-size: min(3vh, 22px);
  font-weight: 400;
  color: #444444;
  padding: 1.2rem 0 0.5rem 0;
}

.feedbackComment {
  font-size: min(2.5vh, 18px);
  width: 80%;
  height: 10rem;
  max-height: 10vh;
  max-width: 40rem;
}

.questionText {
  display: flex;
  justify-content: center;
  font-size: min(3vh, 22px);
  font-weight: 400;
  color: #444444;
  padding: 1rem 1rem 0 1rem;
}

.reasonList {
  font-size: min(3vh, 1.5rem);
  font-weight: 400;
  color: #444444;
}

.buttonClass {
  font-size: min(4vh, 28px);
  font-weight: 400;
  border-color: #4e2c78;
  min-width: 10rem;
}

.Test{
  font-size: 200px;
}

.Layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Header {
  height: 8vh;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Body {
  flex: 1;
  overflow-y: scroll;
  padding: 2rem 0rem;
  margin-top: 8vh;
  margin-bottom: 5vh;
}

.Footer {
  height: 5vh;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1;
}

.qContainer {
  padding: 1rem 0;
}

.question  {
  padding: 1.5vh 0 0 0;
}

.thankBody{
  font-weight: 400;
  font-size: min(6vh, 45px);
  color: #444444;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.App {
  text-align: center;
  font-family: "Helvetica Nue", Helvetica, Arial, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.quiz,.choices {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: min(2.7vh, 19px);
  font-weight: 400;
  color: #444444;
}

.choices label {
  display: flex;
  align-items: center;
}
.choices label,
input[type="radio"], input[type="checkbox"] {
  cursor: pointer;
}
input[type="radio"], input[type="checkbox"] {
  margin-right: 8px;
}

.sticky{
  height: 3rem;
  width: auto;
}

.sticky:hover{
  cursor: pointer;
}
